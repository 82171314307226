import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import ContentWrapper from '../components/contentwrapper/ContentWrapper';
import { Link, useNavigate } from 'react-router-dom';
import { fetchAllAgentDataAPI, fetchFilterAgentDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaRegEye } from 'react-icons/fa';
import { Badge, Button, Table } from 'react-bootstrap';
import moment from 'moment';
import { numberFormat } from '../components/numberFormat';
import { FiRefreshCcw } from 'react-icons/fi';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { numberMasking } from '../components/numberMasking';

const AllOrder = () => {
    const [agentorder, setAgentOrder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const agentid = localStorage.getItem("agentdata");
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 50;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = agentorder.slice(firstIndex, lastIndex);
    const npage = Math.ceil(agentorder.length / recordPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    useEffect(() => {
        if (agentid) {
            navigate('/all-orders');
            getAllAgentCreatedOrder(agentid);
        } else {
            navigate("/");
        }
    }, [agentid]);

    const getAllAgentCreatedOrder = (agentid) => {
        setIsloading(true);
        fetchAllAgentDataAPI('agent-get-all-created-order-data', agentid).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setAgentOrder(res.data.order);
            } else {
                setIsloading(false);
                toast.warning("Check your connection");
            }
        });
    };

    // Debounce function to limit API calls during search
    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const handleSearchByMobile = debounce(async (e) => {
        if (e.target.value.length === 10) {
            const filtervalue = e.target.value;
            if (filtervalue) {
                fetchFilterAgentDataAPI('agent-get-filter-order-by-mobile-number-data', filtervalue).then((res) => {
                    if (res.data.status === 1) {
                        setAgentOrder(res.data.order);
                    } else {
                        setAgentOrder([]);
                        setIsloading(false);
                    }
                });
            } else {
                getAllAgentCreatedOrder(agentid);
            }
        }
    }, 300);

    const refreshOrderButton = () => {
        getAllAgentCreatedOrder(agentid);
    };

    // Pagination Functions
    const perPage = useCallback(() => {
        if (currentPage !== 1) {
            setCurrentPage((prev) => prev - 1);
        }
    }, [currentPage]);

    const changePage = useCallback((id) => {
        setCurrentPage(id);
    }, []);

    const nextPage = useCallback(() => {
        if (currentPage !== npage) {
            setCurrentPage((prev) => prev + 1);
        }
    }, [currentPage, npage]);

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:AGENT::ALL ORDERS</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center justify-content-between">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All ORDERS</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-sm-flex">
                            <input
                                style={{ width: "250px" }}
                                name="cmobile"
                                id="cmobile"
                                className="form-control"
                                type="text"
                                placeholder="Search by Mobile Number"
                                minLength={10}
                                maxLength={10}
                                pattern="[6789][0-9]{9}"
                                onKeyUp={handleSearchByMobile}
                            />
                            <Button onClick={refreshOrderButton} className="btn-sm btn-secondary">
                                <FiRefreshCcw /> Refresh
                            </Button>
                        </div>
                    </div>

                    <div className="card tablecard">
                        <div className="table-responsive">
                            <Table className="table-sm" responsive="lg" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Advance Amt</th>
                                        <th>Balance Amt</th>
                                        <th>Total Price</th>
                                        <th>Create Date</th>
                                        <th>Dispatched Date</th>
                                        <th>Delivery Status</th>
                                        <th>Order Status</th>
                                        <th>Confirm To</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ? (
                                        [...Array(6)].map((_, i) => (
                                            <tr key={i}>
                                                {Array.from({ length: 15 }).map((_, index) => (
                                                    <td key={index}>
                                                        <Skeleton animation="wave" variant="text" />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    ) : records.length ? (
                                        records.map((record, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{record?.order_number}</td>
                                                <td data-tooltip-id={`my-tooltip-${index}`}>
                                                    {record?.order_name}
                                                    <ReactTooltip
                                                        id={`my-tooltip-${index}`}
                                                        place="bottom"
                                                        content={record?.order_address}
                                                    />
                                                </td>
                                                <td>{numberMasking(record?.order_mobile)}</td>
                                                <td>{record?.order_state}</td>
                                                <td>{record?.order_city}</td>
                                                <td>{numberFormat(record?.order_advance_amt)}</td>
                                                <td>{numberFormat(record?.total_balance)}</td>
                                                <td>{numberFormat(record?.grand_total)}</td>
                                                <td>{moment(record?.created_date).format('Do MMM YYYY')}</td>
                                                <td>{record?.dispatch_date}</td>
                                                <td>{record?.shipping_status}</td>
                                                <td>{record?.order_confirm_status}</td>
                                                <td>{record?.order_confirm_to}</td>
                                                <td>
                                                    <Link target="_blank" to={`/invoice/${record.order_number}`} className="badge badge-success">
                                                        <FaRegEye style={{ fontSize: "20px" }} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="text-center" colSpan={15}>Data not found...</td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Advance Amt</th>
                                        <th>Balance Amt</th>
                                        <th>Total Price</th>
                                        <th>Create Date</th>
                                        <th>Dispatched Date</th>
                                        <th>Delivery Status</th>
                                        <th>Order Status</th>
                                        <th>Confirm To</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <nav>
                                <ul className="pagination justify-content-end round-pagination">
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                    </li>
                                    {numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                        </li>
                                    ))}
                                    <li className={`page-item ${currentPage === npage ? 'disabled' : ''}`}>
                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    );
};

export default AllOrder;
